<template>
  <div class = "c-faq-reorganize">
    <Agreement></Agreement>
    <AccountAndTransaction></AccountAndTransaction>
    <ServicesAndSolutions></ServicesAndSolutions>
    <AssetsAndFinances></AssetsAndFinances>
    <DepositAndWithdrawalProcess></DepositAndWithdrawalProcess>
    <TroubleshootsAndSupport></TroubleshootsAndSupport>
  </div>
</template>
<script>

import Agreement from './Reorganizing/Agreement.vue'
import AccountAndTransaction from './Reorganizing/AccountAndTransaction.vue'
import ServicesAndSolutions from './Reorganizing/ServicesAndSolutions.vue'
import AssetsAndFinances from './Reorganizing/AssetsAndFinances.vue'
import DepositAndWithdrawalProcess from './Reorganizing/DepositAndWithdrawalProcess.vue'
import TroubleshootsAndSupport from './Reorganizing/TroubleshootsAndSupport.vue'

export default {
  name: 'FAQ',
  components: {
    Agreement,
    AccountAndTransaction,
    ServicesAndSolutions,
    AssetsAndFinances,
    DepositAndWithdrawalProcess,
    TroubleshootsAndSupport,
  },
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>

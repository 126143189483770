<template>
  <div class="c-faq">
    <div class="c-faq__title">
      <h1 class="c-faq__title-text">Complete FAQ</h1>
      <h2 class="c-faq__title-text-second">on getting started with Sparrow</h2>
    </div>
    <div class="c-faq__subtitle">
      <p class="c-faq__subtitle-text">Need help getting started? Here's a list of frequently asked questions when setting up an options trading account on Sparrow.</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faqList: []
    }
  },
  mounted() {

  },
  methods: {

  },
}
</script>

<template>
  <div class="c-faq-reorganize__list">
    <div class="c-faq-reorganize__list-head" @click="toggleHead()">
      <p class="c-faq-reorganize__list-head-text">Accounts and Transactions</p>
      <img class="c-faq-reorganize__list-head-img" src="@/assets/images/arrow-down.svg" v-if="!headClicked">
      <img class="c-faq-reorganize__list-head-img" src="@/assets/images/arrow-up.svg" v-else>
    </div>
    <div class="c-faq-reorganize__list-body" :class="{'c-faq-reorganize__list-body_active': headClicked}">
      <div class="c-faq-reorganize__list-body-content" :class="{'c-faq-reorganize__open-body': headClicked}">
        <div class="c-faq-reorganize__list-body-content-list">
          <div class="c-faq-reorganize__list-body-content-list-title-wrapper" @click="toggleBody(0)" >
            <p class="c-faq-reorganize__list-body-content-list-title">How will this impact my current account and transactions with Sparrow?</p>
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[0].clicked">
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-min.svg" v-else>
          </div>
          <div class="c-faq-reorganize__list-subbody" :class="{'c-faq-reorganize__open-child': statusBody[0].clicked, 'c-faq-reorganize__list-subbody_active' : statusBody[0].clicked}">
            <div class="c-faq-reorganize__list-subbody-content c-faq-reorganize__list-subbody-content-no-paddingbot" :class="{'c-faq-reorganize__list-subbody-content_inactive': statusBody[0].statusSubBody[0].clicked == false && statusBody[0].statusSubBody[1].clicked == false}">
              <div class="c-faq-reorganize__list-subbody-content-desc">
                We expect minimal impact to our clients’ trading activities as most of the changes are with regards to our corporate structure. We have worked hard to keep Sparrow products and services as familiar as possible. Simply log into your Sparrow Account and:
                <div class="c-faq-reorganize__list-subbody-content-desc-list">
                  <span class="c-faq-reorganize__list-subbody-content-desc-list-numbers">
                    <span class="c-faq-reorganize__list-body-number">1.</span>
                    <span class="c-faq-reorganize__list-body-text">Accept the new Group <a class="c-faq-reorganize__list-subbody-content-child-list-desc_link" href="/legal/user-agreement" target="_blank">Terms of Use</a> and <a class="c-faq-reorganize__list-subbody-content-child-list-desc_link" href="/legal/privacy-policies" target="_blank">Privacy Statement</a>, <a class="c-faq-reorganize__list-subbody-content-child-list-desc_link" href="/legal/disclaimers-and-risk-warning" target="_blank">Risk Statement and Disclaimers</a>.</span>
                    <span class="c-faq-reorganize__list-body-number">2.</span>
                    <span class="c-faq-reorganize__list-body-text">Select the products you wish to use on the Sparrow platform. <span class="c-faq-reorganize__list-subbody-content-desc_bold">Please note:</span> ConvertNOW is a required product. </span>
                    <span class="c-faq-reorganize__list-body-number">3.</span>
                    <span class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom20">To prevent disruptions to deposits and withdrawals, you will need to whitelist your wallets. If you are using a private unhosted wallet, you are required to perform a Satoshi Test which requires you to send a small test deposit from the private unhosted wallet to our designated deposit address. After you complete the Satoshi Test, Sparrow will credit the test deposit amount and blockchain transaction fees to your Sparrow Account.</span>
                  </span>
                  After that, you can continue to enjoy your selected Sparrow products and services.
                </div>
              </div>
              <div class="c-faq-reorganize__list-subbody-content-child">
                <div class="c-faq-reorganize__list-subbody-content-child-title-wrapper" @click="toggleSubBody(0,0)">
                  <ul class="c-faq-reorganize__list-subbody-content-unorderlist c-faq-reorganize__list-subbody-content-unorderlist-border">
                    <li class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_last">How do I go about opting in and out of the product features setting?</li>
                  </ul>
                  <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[0].statusSubBody[0].clicked">
                  <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-min.svg" v-else>
                </div>
                <div class="c-faq-reorganize__list-subbody-content-child-list c-faq-reorganize__list-subbody-content-unorderlist-border" :class="{'c-faq-reorganize__open-grandchild': statusBody[0].statusSubBody[0].clicked, 'c-faq-reorganize__list-subbody-content-child-list_active' : statusBody[0].statusSubBody[0].clicked}">
                  <div class="c-faq-reorganize__list-subbody-content-child-list-desc">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number">1.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom20">Log into your Sparrow’s account.</p>
                    </div>
                    <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-opt-product-1.png">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number">2.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom20">A ‘<span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Terms of Use & Privacy and Data protection Policy</span>’ will appear once.</p>
                    </div>
                    <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-opt-product-2.png">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number">3.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom20">Click on <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Accept & Continue</span>.</p>
                    </div>
                    <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-opt-product-3.png">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number">4.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-body-marginbottom70">Under the ‘<span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Account</span>’ tab, you can view all your products and toggle between opt-in or opt-up on the slider bar.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-faq-reorganize__list-subbody-content-child">
                <div class="c-faq-reorganize__list-subbody-content-child-title-wrapper" @click="toggleSubBody(0,1)">
                  <ul class="c-faq-reorganize__list-subbody-content-unorderlist" :class="{'c-faq-reorganize__list-subbody-content-unorderlist-border': statusBody[0].statusSubBody[1].clicked}">
                    <li class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_last">How to whitelist your wallet and perform a mandatory Satoshi Test for private wallets.</li>
                  </ul>
                  <!-- <p class="c-faq-reorganize__list-subbody-content-child-title" :class="{'c-faq-reorganize__list-subbody-content-child-title_last': !statusBody[0].statusSubBody[1].clicked}">• How to whitelist your wallet and perform a mandatory Satoshi Test for private wallets.</p> -->
                  <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[0].statusSubBody[1].clicked">
                  <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-min.svg" v-else>
                </div>
                <div class="c-faq-reorganize__list-subbody-content-child-list" :class="{'c-faq-reorganize__open-grandchild': statusBody[0].statusSubBody[1].clicked, 'c-faq-reorganize__list-subbody-content-child-list_active' : statusBody[0].statusSubBody[1].clicked}">
                  <div class="c-faq-reorganize__list-subbody-content-child-list-desc c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                    <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-Deposit-and-Withdrawal-1.png">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number">1.</p>
                      <p class="c-faq-reorganize__list-body-text">Log into your Sparrow’s account and and click on the ‘<span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Funds</span>’ tab.</p>
                      <p class="c-faq-reorganize__list-body-number">2.</p>
                      <p class="c-faq-reorganize__list-body-text">Choose either <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">deposit or withdrawal on the navigation bar on the left</span>.</p>
                      <p class="c-faq-reorganize__list-body-number">3.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom40">Under the deposit from section, <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">click on select</span> and a ‘Select BTC Address’ pop-up will appear.</p>
                    </div>

                    <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-Deposit-and-Withdrawal-2.png">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number">4.</p>
                      <p class="c-faq-reorganize__list-body-text">For <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">exchange wallet</span>, choose from one of the selections under the dropdown and label it accordingly.</p>
                      <p class="c-faq-reorganize__list-body-number">5.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom40">For <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">private wallet</span>, choose from one of the selections under the dropdown, label it accordingly, and input your wallet address.</p>
                    </div>

                    <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-Deposit-and-Withdrawal-3.png">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number">6.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom40">Your ‘<span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Name</span>’, and ‘<span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Date of Birth</span>’ will be auto-populated. Simply <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">click on the ‘Add’ button</span>.</p>
                    </div>

                    <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-Deposit-and-Withdrawal-4.png">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number c-faq-reorganize__list-body-number-list">7a.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-body-text-list c-faq-reorganize__list-subbody-content-desc-paddingBottom40">You will be prompted to enter your Two-Factor Authentication Code (2FA).</p>
                    </div>

                    <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-Deposit-and-Withdrawal-5.png">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number c-faq-reorganize__list-body-number-list">7b.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-body-text-list c-faq-reorganize__list-subbody-content-desc-paddingBottom20"><span class="c-faq-reorganize__list-subbody-content-desc_bold">For users who have <span class="c-faq-reorganize__list-subbody-content-desc_red">not activated</span> their Two-Factor Authentication Code (2FA).</span><br/>
                      A whitelist request email will be sent to the email address you signed up with. Verify the request by
                      clicking on the link in the email.
                      </p>
                    </div>
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number">8.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom40">For <span class="c-faq-reorganize__list-subbody-content-desc_bold">private wallet</span>, the verification process will require you to send a small test deposit from the private
                      wallet of your choice to our designated deposit address. Upon validation completion, Sparrow will credit
                      the test deposit amount and blockchain transaction fees to your Sparrow account.</p>
                    </div>

                    <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-Deposit-and-Withdrawal-6.png">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number">9.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom70">Once you have clicked on the link in the email, you can view the status of your verification process. Your
                      wallet is <span class="c-faq-reorganize__list-subbody-content-desc_bold">successfully whitelisted once the status shows available in green</span>.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="c-faq-reorganize__list-body-content-list">
          <div class="c-faq-reorganize__list-body-content-list-title-wrapper" @click="toggleBody(1)">
            <p class="c-faq-reorganize__list-body-content-list-title">Do I need to open multiple/different accounts under the respective entities?</p>
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[1].clicked">
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-min.svg" v-else>
          </div>
          <div class="c-faq-reorganize__list-subbody" :class="{'c-faq-reorganize__open-child': statusBody[1].clicked, 'c-faq-reorganize__list-subbody_active' : statusBody[1].clicked}">
            <div class="c-faq-reorganize__list-subbody-content c-faq-reorganize__list-subbody-content-no-paddingbot" :class="{'c-faq-reorganize__list-subbody-content_inactive': statusBody[1].statusSubBody[0].clicked == false && statusBody[1].statusSubBody[1].clicked == false}">
              <p class="c-faq-reorganize__list-subbody-content-desc">You will not need to open multiple/different accounts.</p>

              <div class="c-faq-reorganize__list-subbody-content-child">
                <div class="c-faq-reorganize__list-subbody-content-child-title-wrapper" @click="toggleSubBody(1,0)">
                  <ul class="c-faq-reorganize__list-subbody-content-unorderlist c-faq-reorganize__list-subbody-content-unorderlist-border">
                    <li class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_last">Will I be able to use my account if I choose to do nothing?</li>
                  </ul>
                  <!-- <p class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_first">• Will I be able to use my account if I choose to do nothing?</p> -->
                  <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[1].statusSubBody[0].clicked">
                  <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-min.svg" v-else>
                </div>
                <div class="c-faq-reorganize__list-subbody-content-child-list" :class="{'c-faq-reorganize__open-grandchild': statusBody[1].statusSubBody[0].clicked, 'c-faq-reorganize__list-subbody-content-child-list_active' : statusBody[1].statusSubBody[0].clicked}">
                  <div class="c-faq-reorganize__list-subbody-content-child-list-desc">
                    <p class="c-faq-reorganize__list-subbody-content-child-list-desc-text">You will need to accept the Group <a class="c-faq-reorganize__list-subbody-content-child-list-desc_link" href="/legal/user-agreement" target="_blank">Terms of Use</a> and <a class="c-faq-reorganize__list-subbody-content-child-list-desc_link" href="/legal/privacy-policies" target="_blank">Privacy Statement</a>, <a class="c-faq-reorganize__list-subbody-content-child-list-desc_link" href="/legal/disclaimers-and-risk-warning" target="_blank">Risk Statement and Disclaimers</a> to continue to enjoy Sparrow products and services.</p>
                  </div>
                </div>
              </div>

              <div class="c-faq-reorganize__list-subbody-content-child">
                <div class="c-faq-reorganize__list-subbody-content-child-title-wrapper" @click="toggleSubBody(1,1)">
                  <ul class="c-faq-reorganize__list-subbody-content-unorderlist" :class="{'c-faq-reorganize__list-subbody-content-unorderlist-border': statusBody[1].statusSubBody[1].clicked}">
                    <li class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_last">How many account logins do I need to maintain?</li>
                  </ul>
                  <!-- <p class="c-faq-reorganize__list-subbody-content-child-title" :class="{'c-faq-reorganize__list-subbody-content-child-title_last': !statusBody[1].statusSubBody[1].clicked}">• How many account logins do I need to maintain? </p> -->
                  <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[1].statusSubBody[1].clicked">
                  <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-min.svg" v-else>
                </div>
                <div class="c-faq-reorganize__list-subbody-content-child-list" :class="{'c-faq-reorganize__open-grandchild': statusBody[1].statusSubBody[1].clicked, 'c-faq-reorganize__list-subbody-content-child-list_active' : statusBody[1].statusSubBody[1].clicked}">
                  <div class="c-faq-reorganize__list-subbody-content-child-list-desc">
                    <p class="c-faq-reorganize__list-subbody-content-child-list-desc-text c-faq-reorganize__list-subbody-content-child-list-desc-text_last">You are only required to maintain one Sparrow Account. This will not change the way you log in to your account. </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="c-faq-reorganize__list-body-content-list ">
          <div class="c-faq-reorganize__list-body-content-list-title-wrapper" @click="toggleBody(2)">
            <p class="c-faq-reorganize__list-body-content-list-title">How do I check my balances in my statement of accounts? </p>
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[2].clicked">
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-min.svg" v-else>
          </div>
          <div class="c-faq-reorganize__list-subbody c-faq-reorganize__list-subbody-content-child-title_last" :class="{'c-faq-reorganize__open-child': statusBody[2].clicked, 'c-faq-reorganize__list-subbody_active' : statusBody[2].clicked}">
            <div class="c-faq-reorganize__list-subbody-content c-faq-reorganize__list-subbody-content-no-paddingbot" :class="{'c-faq-reorganize__list-subbody-content_inactive': statusBody[2].statusSubBody[0].clicked == false}">
              <p class="c-faq-reorganize__list-subbody-content-desc">Simply log in to your Sparrow Account to view your account balance and trading activities.</p>

              <div class="c-faq-reorganize__list-subbody-content-child">
                <div class="c-faq-reorganize__list-subbody-content-child-title-wrapper" @click="toggleSubBody(2,0)">
                  <ul class="c-faq-reorganize__list-subbody-content-unorderlist c-faq-reorganize__list-subbody-content-unorderlist-border">
                    <li class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_last">Will I continue to receive consolidated statements?</li>
                  </ul>
                  <!-- <p class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_first" :class="{'c-faq-reorganize__list-subbody-content-child-title_last': !statusBody[2].statusSubBody[0].clicked}">• Will I continue to receive consolidated statements? </p> -->
                  <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[2].statusSubBody[0].clicked">
                  <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-min.svg" v-else>
                </div>
                <div class="c-faq-reorganize__list-subbody-content-child-list " :class="{'c-faq-reorganize__open-grandchild': statusBody[2].statusSubBody[0].clicked, 'c-faq-reorganize__list-subbody-content-child-list_active' : statusBody[2].statusSubBody[0].clicked}">
                  <div class="c-faq-reorganize__list-subbody-content-child-list-desc">
                    <p class=" c-faq-reorganize__list-subbody-content-no-paddingbot c-faq-reorganize__list-subbody-content-child-title_last_no-border-bottom c-faq-reorganize__list-subbody-content-child-list-desc-text">You will receive a consolidated monthly statement of accounts, history, and transactions on the Sparrow platform.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      headClicked: false,
      statusBody: [
        {clicked: false, statusSubBody: [{clicked: false}, {clicked: false}]},
        {clicked: false, statusSubBody: [{clicked: false}, {clicked: false}]},
        {clicked: false, statusSubBody: [{clicked: false}]},
      ]
    }
  },
  mounted() {

  },
  methods: {
    toggleHead() {
      let _this = this;
      this.headClicked = !this.headClicked
      this.$nextTick(()=>{
        _this.adjustHeight(1, -1, -1);
      })
    },
    toggleBody(index) {
      let _this = this;
      this.statusBody[index].clicked = !this.statusBody[index].clicked
      this.$nextTick(()=>{
        _this.adjustHeight(1, index, -1);
      })
    },
    toggleSubBody(idxBody, idxSub) {
      let _this = this;
      this.statusBody[idxBody].statusSubBody[idxSub].clicked = !this.statusBody[idxBody].statusSubBody[idxSub].clicked
      this.$nextTick(()=>{
        _this.adjustHeight(1, idxBody, idxSub);
      })
    },
    adjustHeight:function(idx, idxChild, idxGrandChild){
      let grandChildElements, childElements, bodyElements
      bodyElements = document.getElementsByClassName('c-faq-reorganize__list-body')
      if(idxGrandChild>=0){
        childElements = bodyElements[idx].getElementsByClassName('c-faq-reorganize__list-subbody')
        grandChildElements = childElements[idxChild].getElementsByClassName('c-faq-reorganize__list-subbody-content-child-list')
        if(grandChildElements[idxGrandChild].classList.contains('c-faq-reorganize__open-grandchild')){
          let grandChildBodyHeight = grandChildElements[idxGrandChild].scrollHeight + 90
          grandChildElements[idxGrandChild].style.maxHeight = grandChildBodyHeight+'px'
          this.scrollToFAQ(grandChildElements[idxGrandChild].offsetTop);
        }
        else{
          grandChildElements[idxGrandChild].style.maxHeight ='0px'
        }
        childElements[idxChild].style.maxHeight = '9999px'
        bodyElements[idx].style.maxHeight = '9999px'
      }
      else{
        if(idxChild>=0){
          childElements = bodyElements[idx].getElementsByClassName('c-faq-reorganize__list-subbody')
          if(childElements[idxChild].classList.contains('c-faq-reorganize__open-child')){
            let childBodyHeight = childElements[idxChild].scrollHeight + 90
            childElements[idxChild].style.maxHeight = childBodyHeight+'px'
            this.scrollToFAQ(childElements[idxChild].offsetTop);
          }
          else{
            childElements[idxChild].style.maxHeight = '0px'
          }
          bodyElements[idx].style.maxHeight = '9999px'
        }
        else{
          if(bodyElements[idx].classList.contains('c-faq-reorganize__list-body_active')){
            let bodyHeight = bodyElements[idx].scrollHeight + 90
            bodyElements[idx].style.maxHeight = bodyHeight+'px'
            this.scrollToFAQ(bodyElements[idx].offsetTop);
          }
          else{
            bodyElements[idx].style.maxHeight = '0px'
          }
        }
      }
    },
    scrollToFAQ(position) {
      $('html,body').animate({scrollTop: position - 170},'slow');
    }
  }
}
</script>

<template>
  <div class="c-faq">
    <div class="c-faq__title c-faq__title_reorganize">
      <h1 class="c-faq__title-text">Sparrow is reorganizing</h1>
      <h2 class="c-faq__title-text-second">to serve you better</h2>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faqList: []
    }
  },
  mounted() {

  },
  methods: {

  },
}
</script>

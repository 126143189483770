<template>
  <div class = "c-faq-list">
  <div class = "c-faq-list__container">
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click="toggleActive(0)">
        <p class = "c-faq-list__title">What is the Major Payment Institution (MPI) licence?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqList[0].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__ipa-body" :class="{'c-faq-list__ipa-body_active':activeFaqList[0].status == true}">
        <div class="c-faq-list__body-content">
          <p class="c-faq-list__body-content-text">The Major Payment Institution (MPI) licence is granted by the Monetary Authority of Singapore (MAS) to successful applicants to provide payment services, such as Digital Payment Token (DPT) services, after the applicant has satisfied all the conditions imposed by MAS in its in-principle approval granted to the applicant. It offers regulatory clarity and heightens consumer protection while accelerating the growth of FinTech and payment services in Singapore.</p>
          <p class="c-faq-list__body-content-text">
          Sparrow Tech Private Limited is pleased to receive the MPI licence from MAS to provide DPT services under the PS Act.
          </p>
        </div>
        
      </div>
    </div>
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(1)">
        <p class = "c-faq-list__title">What is the significance of Sparrow obtaining the Major Payment Institution (MPI) licence from the Monetary Authority of Singapore (MAS) to provide digital payment token services?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqList[1].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class="c-faq-list__ipa-body" :class="{'c-faq-list__ipa-body_active':activeFaqList[1].status == true}">
        <div class="c-faq-list__body-content">
          <p class="c-faq-list__body-content-text">To operate with transparency and integrity, Sparrow places regulatory compliance at the heart of our businesses. The Major Payment Institution (MPI) licence granted by the Monetary Authority of Singapore (MAS) to Sparrow underlines our dedication to providing digital payment token services with a strong focus on compliance to safeguard the interests of our clients and to ensure that they are treated fairly and with respect.</p>
        </div>
      </div>
    </div>
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(2)">
        <p class = "c-faq-list__title">Why is the Major Payment Institution (MPI) an important evaluation factor for financial institutions seeking Digital Payment Token Services?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqList[2].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__ipa-body" :class="{'c-faq-list__ipa-body_active':activeFaqList[2].status == true}">
        <div class="c-faq-list__body-content">
          <p class="c-faq-list__body-content-text">The Major Payment Institution (MPI) licence serves to heighten consumer protection by ensuring that a licensee has in place robust systems, policies, and procedures that includes Anti-Money Laundering and Countering the Financing of Terrorism (AML/CFT) controls, audit requirements, and cyber hygiene practices. It is a testament to a digital asset partner’s commitment to increasing the reliability and trustworthiness of services and products while achieving high operational standards.</p>
        </div>
      </div>
    </div>
    
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(3)">
        <p class = "c-faq-list__title">What must applicants fulfill to obtain the Major Payment Institution licence from the Monetary Authority of Singapore (MAS)?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqList[3].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__ipa-body" :class="{'c-faq-list__ipa-body_active':activeFaqList[3].status == true}">
        <div class="c-faq-list__body-content">
         <p class = "c-faq-list__body-content-text2">To obtain the Major Payment Institution licence from the Monetary Authority of Singapore (MAS), applicants must inter alia fulfill and implement the following systems, policies, and procedures:</p>
          <br>
        <ol class="c-faq-list__ipa-orderlist">
            <li class = "c-faq-list__body-content-text2">Anti-Money Laundering and Countering the Financing of Terrorism (AML/CFT) Requirements</li>
            <li class = "c-faq-list__body-content-text2">Periodic Returns</li>
            <li class = "c-faq-list__body-content-text2">Cyber Hygiene</li>
            <li class = "c-faq-list__body-content-text2">Disclosures and Communications</li>
            <li class = "c-faq-list__body-content-text">Annual Audit Requirements</li>
          </ol>
          
        </div>
      </div>
    </div>
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(4)">
        <p class = "c-faq-list__title">With the Major Payment Institution (MPI) licence, is Sparrow regulated under the Monetary Authority of Singapore (MAS) to provide Digital Payment Token (DPT) services?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqList[4].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__ipa-body" :class="{'c-faq-list__ipa-body_active':activeFaqList[4].status == true}">
        <div class = "c-faq-list__body-content">
          <p class = "c-faq-list__body-content-text">Yes, Sparrow Tech Private Limited is licensed and regulated under the Payment Services Act (PS Act) to provide Digital Payment Token (DPT) services.</p>
        </div>
      </div>
    </div>
    
  <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(5)">
        <p class = "c-faq-list__title">Will there be changes to the products and services at Sparrow?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqList[5].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__ipa-body" :class="{'c-faq-list__ipa-body_active':activeFaqList[5].status == true}">
        <div class="c-faq-list__body-content">
          <p class = "c-faq-list__body-content-text2">There will be no changes to the design of the following products and services:</p>
          <ol class="c-faq-list__ipa-orderlist">
            <li class = "c-faq-list__body-content-text2 c-faq-list__body-content-number">ConvertNOW</li>
            <li class = "c-faq-list__body-content-text2 c-faq-list__body-content-number">Deposit of digital payment tokens</li>
            <li class = "c-faq-list__body-content-text2 c-faq-list__body-content-number">Withdrawal of digital payment tokens</li>
            <li class = "c-faq-list__body-content-text c-faq-list__body-content-number">Sparrow Options</li>
          </ol>
          <p class = "c-faq-list__body-content-text2">Sparrow Options and structured products and services are not currently regulated under the Payment Services Act. Products and services regulated under the Payment Services Act are, as follows:</p>
          <ol class="c-faq-list__ipa-orderlist">
            <li class = "c-faq-list__body-content-text2 c-faq-list__body-content-number">ConvertNOW</li>
            <li class = "c-faq-list__body-content-text2 c-faq-list__body-content-number">Deposit of digital payment tokens</li>
            <li class = "c-faq-list__body-content-text c-faq-list__body-content-number">Withdrawal of digital payment tokens</li>
          </ol>
        </div>
      </div>
    </div>
<div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(6)">
        <p class = "c-faq-list__title">Where can I get more information with regard to regulations, agreements, risk warnings, and privacy policies?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqList[6].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__ipa-body" :class="{'c-faq-list__ipa-body_active':activeFaqList[6].status == true}">
        <div class = "c-faq-list__body-content">
          <p class = "c-faq-list__body-content-text">To get more information on regulation, user agreement, risk warning, and privacy policies visit <span class="c-faq-list__body-content-text_url" @click="openLink('https://sparrowexchange.com/legal/disclaimers-and-risk-warning/')">https://sparrowexchange.com/legal/disclaimers-and-risk-warning/</span> and use the navigation panel on the left of the page to view each topic.</p>
        </div>
      </div>
    </div>
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(7)">
        <p class = "c-faq-list__title">If I have more queries pertaining to the licence and regulation, who can I approach?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqList[7].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__ipa-body" :class="{'c-faq-list__ipa-body_active':activeFaqList[7].status == true}">
        <div class = "c-faq-list__body-content">
          <p class = "c-faq-list__body-content-text">Your feedback and concerns are important to us. Please contact your <span class="c-faq-list__body-content-text_url" @click="openLink('https://sparrowexchange.com/contact-us/reach-out')">Relationship Manager</span> or you may send a <span class="c-faq-list__body-content-text_url" @click="openLink('https://sparrowexchange.com/support/sparrowcares')">support ticket request</span> and we will address your query soonest.</p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import $ from 'jquery'
export default {
  name: 'FAQ',
  data() {
    return {
      activeFaq: -1,
      offsetList: [],
      statusOpened: false,
      activeFaqList: [
        {status:false},
        {status:false},
        {status:false},
        {status:false},
        {status:false},
        {status:false},
        {status:false},
        {status:false},
      ]
    }
  },
  methods: {
    toggleActive:function(idx){
      let _this = this;
      if(this.activeFaqList[idx].status == true) {
        this.activeFaqList[idx].status = false
        this.statusOpened = false
      } else {
        this.statusOpened = true
        this.activeFaqList[idx].status = true
      }
      this.$nextTick(()=>{
        _this.adjustHeight(idx);
      })
    },
    adjustHeight:function(idx){
      let bodyElements = document.getElementsByClassName('c-faq-list__ipa-body')
      if(bodyElements[idx].classList.contains('c-faq-list__ipa-body_active')){
        let bodyHeight = bodyElements[idx].scrollHeight + 70
        bodyElements[idx].style.maxHeight = bodyHeight+'px'
        this.scrollToFAQ(bodyElements[idx].offsetTop);
      }
      else{
        bodyElements[idx].style.maxHeight = '0px'
      }
    },
    scrollToFAQ(position) {
      $('html,body').animate({scrollTop: position - 170},'slow');
    }
  },
}
</script>

<template>
  <div class="c-faq-reorganize__list">
    <div class="c-faq-reorganize__list-head" @click="toggleHead()">
      <p class="c-faq-reorganize__list-head-text">Assets and Finances</p>
      <img class="c-faq-reorganize__list-head-img" src="@/assets/images/arrow-down.svg" v-if="!headClicked">
      <img class="c-faq-reorganize__list-head-img" src="@/assets/images/arrow-up.svg" v-else>
    </div>
    <div class="c-faq-reorganize__list-body" :class="{'c-faq-reorganize__list-body_active': headClicked}">
      <div class="c-faq-reorganize__list-body-content" :class="{'c-faq-reorganize__open-body': headClicked}">

        <div class="c-faq-reorganize__list-body-content-list">
          <div class="c-faq-reorganize__list-body-content-list-title-wrapper" @click="toggleBody(0)" >
            <p class="c-faq-reorganize__list-body-content-list-title">What happens to my assets deposited in Sparrow?</p>
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[0].clicked">
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-min.svg" v-else>
          </div>
          <div class="c-faq-reorganize__list-subbody c-faq-reorganize__list-subbody-content-child-title_last" :class="{'c-faq-reorganize__open-child': statusBody[0].clicked, 'c-faq-reorganize__list-subbody_active' : statusBody[0].clicked}">
            <div class="c-faq-reorganize__list-subbody-content c-faq-reorganize__list-subbody-content-no-paddingbot">
              All assets deposited into Sparrow are stored with BitGo, a world class custodian.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      headClicked: false,
      statusBody: [
        {clicked: false},
      ]
    }
  },
  mounted() {

  },
  methods: {
    toggleHead() {
      let _this = this;
      this.headClicked = !this.headClicked
      this.$nextTick(()=>{
        _this.adjustHeight(3, -1, -1);
      })
    },
    toggleBody(index) {
      let _this = this;
      this.statusBody[index].clicked = !this.statusBody[index].clicked
      this.$nextTick(()=>{
        _this.adjustHeight(3, index, -1);
      })
    },
    // toggleSubBody(idxBody, idxSub) {
    //   let _this = this;
    //   this.statusBody[idxBody].statusSubBody[idxSub].clicked = !this.statusBody[idxBody].statusSubBody[idxSub].clicked
    //   this.$nextTick(()=>{
    //     _this.adjustHeight(0, idxBody, idxSub);
    //   })
    // },
    adjustHeight:function(idx, idxChild, idxGrandChild){
      let grandChildElements, childElements, bodyElements
      bodyElements = document.getElementsByClassName('c-faq-reorganize__list-body')
      if(idxGrandChild>=0){
        childElements = bodyElements[idx].getElementsByClassName('c-faq-reorganize__list-subbody')
        grandChildElements = childElements[idxChild].getElementsByClassName('c-faq-reorganize__list-subbody-content-child-list')
        if(grandChildElements[idxGrandChild].classList.contains('c-faq-reorganize__open-grandchild')){
          let grandChildBodyHeight = grandChildElements[idxGrandChild].scrollHeight + 90
          grandChildElements[idxGrandChild].style.maxHeight = grandChildBodyHeight+'px'
          this.scrollToFAQ(grandChildElements[idxGrandChild].offsetTop);
        }
        else{
          grandChildElements[idxGrandChild].style.maxHeight ='0px'
        }
        childElements[idxChild].style.maxHeight = '999px'
        bodyElements[idx].style.maxHeight = '9999px'
      }
      else{
        if(idxChild>=0){
          childElements = bodyElements[idx].getElementsByClassName('c-faq-reorganize__list-subbody')
          if(childElements[idxChild].classList.contains('c-faq-reorganize__open-child')){
            let childBodyHeight = childElements[idxChild].scrollHeight + 90
            childElements[idxChild].style.maxHeight = childBodyHeight+'px'
            this.scrollToFAQ(childElements[idxChild].offsetTop);
          }
          else{
            childElements[idxChild].style.maxHeight = '0px'
          }
          bodyElements[idx].style.maxHeight = '9999px'
        }
        else{
          if(bodyElements[idx].classList.contains('c-faq-reorganize__list-body_active')){
            let bodyHeight = bodyElements[idx].scrollHeight + 90
            bodyElements[idx].style.maxHeight = bodyHeight+'px'
            this.scrollToFAQ(bodyElements[idx].offsetTop);
          }
          else{
            bodyElements[idx].style.maxHeight = '0px'
          }
        }
      }
    },
    scrollToFAQ(position) {
      $('html,body').animate({scrollTop: position - 170},'slow');
    }
  }
}
</script>

<template>
  <div class="c-faq-reorganize__list">
    <div class="c-faq-reorganize__list-head" @click="toggleHead()">
      <p class="c-faq-reorganize__list-head-text">Agreement</p>
      <img class="c-faq-reorganize__list-head-img" src="@/assets/images/arrow-down.svg" v-if="!headClicked">
      <img class="c-faq-reorganize__list-head-img" src="@/assets/images/arrow-up.svg" v-else>
    </div>
    <div class="c-faq-reorganize__list-body" :class="{'c-faq-reorganize__list-body_active': headClicked}">
      <div class="c-faq-reorganize__list-body-content" :class="{'c-faq-reorganize__open-body': headClicked}">
        <div class="c-faq-reorganize__list-body-content-list">
          <div class="c-faq-reorganize__list-body-content-list-title-wrapper" @click="toggleBody(0)" >
            <p class="c-faq-reorganize__list-body-content-list-title">Why is Sparrow reorganizing?</p>
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[0].clicked">
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-min.svg" v-else>
          </div>
          <div class="c-faq-reorganize__list-subbody " :class="{'c-faq-reorganize__open-child': statusBody[0].clicked, 'c-faq-reorganize__list-subbody_active' : statusBody[0].clicked}">
            <div class="c-faq-reorganize__list-subbody-content ">
              <div class="c-faq-reorganize__list-subbody-content-desc c-faq-reorganize__list-subbody-content-child-title_last">
                <div class="c-faq-reorganize__list-subbody-content-desc-paddingBottom20">
                  Sparrow Tech Private Limited has applied for a Major Payment Institution Licence under the Payment Services Act No. 2 of 2019 in 2020.
                </div>
                <div class="c-faq-reorganize__list-subbody-content-desc-paddingBottom20">
                  Sparrow always aims to meet the highest regulatory and compliance standards. In connection with our preparation and to work towards the grant of our Payments Services License in Singapore, we are changing our operating structure to segregate regulated activities from unregulated activities.
                </div>
                <div class="c-faq-reorganize__list-subbody-content-desc-paddingBottom20">
                  From January 3rd 2022, the group  will have 3 operating entities and 1 holding company as per below:<br/>
                </div>
                <div class="">
                  <ul class="c-faq-reorganize__list-subbody-content-unorderlist">
                    <li class="c-faq-reorganize__list-subbody-content-desc_bold">Sparrow Tech</li>
                    <p>Offers digital asset spot conversions which are regulated under the Payment Services Act</p>
                    <li class="c-faq-reorganize__list-subbody-content-desc_bold">Sparrow Digital</li>
                    <p>Offers digital asset derivatives and structures which are not regulated under the Payment Services Act</p>
                    <li class="c-faq-reorganize__list-subbody-content-desc_bold">Sparrow Research</li>
                    <p>Research and Development of new technology and products</p>
                    <li class="c-faq-reorganize__list-subbody-content-desc_bold">Sparrow Holdings</li>
                    <p>Holding company that wholly owns Sparrow Tech, Sparrow Digital, and Sparrow Research</p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-faq-reorganize__list-body-content-list">
          <div class="c-faq-reorganize__list-body-content-list-title-wrapper" @click="toggleBody(1)">
            <p class="c-faq-reorganize__list-body-content-list-title">What do I need to do to continue using my Sparrow Account?</p>
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[1].clicked">
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-min.svg" v-else>
          </div>
          <div class="c-faq-reorganize__list-subbody" :class="{'c-faq-reorganize__open-child': statusBody[1].clicked, 'c-faq-reorganize__list-subbody_active' : statusBody[1].clicked}">
            <div class="c-faq-reorganize__list-subbody-content c-faq-reorganize__list-subbody-content-no-paddingbot" :class="{'c-faq-reorganize__list-subbody-content_inactive': statusBody[1].statusSubBody[0].clicked == false && statusBody[1].statusSubBody[1].clicked == false}">
              <div class="c-faq-reorganize__list-subbody-content-desc">
                Simply log into your Sparrow Account and complete the below 3 steps:
                <div class="c-faq-reorganize__list-subbody-content-desc-list">
                  <span class="c-faq-reorganize__list-subbody-content-desc-list-numbers">
                    <span class="c-faq-reorganize__list-body-number">1.</span>
                    <span class="c-faq-reorganize__list-body-text">Accept the new Group <a class="c-faq-reorganize__list-subbody-content-child-list-desc_link" href="/legal/user-agreement" target="_blank">Terms of Use</a> and <a class="c-faq-reorganize__list-subbody-content-child-list-desc_link" href="/legal/privacy-policies" target="_blank">Privacy Statement</a>, <a class="c-faq-reorganize__list-subbody-content-child-list-desc_link" href="/legal/disclaimers-and-risk-warning" target="_blank">Risk Statement and Disclaimers</a>.</span>
                    <span class="c-faq-reorganize__list-body-number">2.</span>
                    <span class="c-faq-reorganize__list-body-text">Select the products you wish to use on the Sparrow platform. <span class="c-faq-reorganize__list-subbody-content-desc_bold">Please note:</span> ConvertNOW is a required product. </span>
                    <span class="c-faq-reorganize__list-body-number">3.</span>
                    <span class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom20">To prevent disruptions to deposits and withdrawals, you will need to whitelist your wallets. If you are using a private unhosted wallet, you are required to perform a Satoshi Test which requires you to send a small test deposit from the private unhosted wallet to our designated deposit address. After you complete the Satoshi Test, Sparrow will credit the test deposit amount and blockchain transaction fees to your Sparrow Account.</span>
                  </span>
                  After that, you can continue to enjoy your selected Sparrow products and services.
                </div>
              </div>
              <div class="c-faq-reorganize__list-subbody-content-child">
                <div class="c-faq-reorganize__list-subbody-content-child-title-wrapper" @click="toggleSubBody(1,0)">
                  <ul class="c-faq-reorganize__list-subbody-content-unorderlist c-faq-reorganize__list-subbody-content-unorderlist-border">
                    <li class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_last">How do I go about opting in and out of the product features setting?</li>
                  </ul>
                  <!-- <p class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_first">• </p> -->
                  <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[1].statusSubBody[0].clicked">
                  <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-min.svg" v-else>
                </div>
                <div class="c-faq-reorganize__list-subbody-content-child-list" :class="{'c-faq-reorganize__open-grandchild': statusBody[1].statusSubBody[0].clicked, 'c-faq-reorganize__list-subbody-content-child-list_active' : statusBody[1].statusSubBody[0].clicked, 'c-faq-reorganize__list-subbody-content-unorderlist-border': statusBody[1].statusSubBody[0].clicked}">
                  <div class="c-faq-reorganize__list-subbody-content-child-list-desc">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number">1.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom20">Log into your Sparrow’s account.</p>
                    </div>
                    <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-opt-product-1.png">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number">2.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom20">A ‘<span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Terms of Use & Privacy and Data protection Policy</span>’ will appear once.</p>
                    </div>
                    <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-opt-product-2.png">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number">3.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom20">Click on <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Accept & Continue</span>.</p>
                    </div>
                    <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-opt-product-3.png">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number">4.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-body-marginbottom70">Under the ‘<span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Account</span>’ tab, you can view all your products and toggle between opt-in or opt-up on the slider bar.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-faq-reorganize__list-subbody-content-child">
                <div class="c-faq-reorganize__list-subbody-content-child-title-wrapper" @click="toggleSubBody(1,1)">
                  <ul class="c-faq-reorganize__list-subbody-content-unorderlist" :class="{'c-faq-reorganize__list-subbody-content-unorderlist-border':statusBody[1].statusSubBody[1].clicked}">
                    <li class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_last">How to whitelist your wallet and perform a mandatory Satoshi Test for private wallets.</li>
                  </ul>
                  <!-- <p class="c-faq-reorganize__list-subbody-content-child-title" :class="{'c-faq-reorganize__list-subbody-content-child-title_last': !statusBody[1].statusSubBody[1].clicked}">• </p> -->
                  <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[1].statusSubBody[1].clicked">
                  <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-min.svg" v-else>
                </div>
                <div class="c-faq-reorganize__list-subbody-content-child-list" :class="{'c-faq-reorganize__open-grandchild': statusBody[1].statusSubBody[1].clicked, 'c-faq-reorganize__list-subbody-content-child-list_active' : statusBody[1].statusSubBody[1].clicked}">
                  <div class="c-faq-reorganize__list-subbody-content-child-list-desc c-faq-reorganize__list-subbody-content-desc_paddingTop20">

                    <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-Deposit-and-Withdrawal-1.png">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number">1.</p>
                      <p class="c-faq-reorganize__list-body-text">Log into your Sparrow’s account and and click on the ‘<span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Funds</span>’ tab.</p>
                      <p class="c-faq-reorganize__list-body-number">2.</p>
                      <p class="c-faq-reorganize__list-body-text">Choose either <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">deposit or withdrawal on the navigation bar on the left</span>.</p>
                      <p class="c-faq-reorganize__list-body-number">3.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom40">Under the deposit from section, <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">click on select</span> and a ‘Select BTC Address’ pop-up will appear.</p>
                    </div>

                    <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-Deposit-and-Withdrawal-2.png">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number">4.</p>
                      <p class="c-faq-reorganize__list-body-text">For <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">exchange wallet</span>, choose from one of the selections under the dropdown and label it accordingly.</p>
                      <p class="c-faq-reorganize__list-body-number">5.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom40">For <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">private wallet</span>, choose from one of the selections under the dropdown, label it accordingly, and input your wallet address.</p>
                    </div>

                    <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-Deposit-and-Withdrawal-3.png">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number">6.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom40">Your ‘<span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Name</span>’, and ‘<span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Date of Birth</span>’ will be auto-populated. Simply <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">click on the ‘Add’ button</span>.</p>
                    </div>

                    <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-Deposit-and-Withdrawal-4.png">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number c-faq-reorganize__list-body-number-list">7a.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-body-text-list c-faq-reorganize__list-subbody-content-desc-paddingBottom40">You will be prompted to enter your Two-Factor Authentication Code (2FA).</p>
                    </div>

                    <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-Deposit-and-Withdrawal-5.png">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number c-faq-reorganize__list-body-number-list">7b.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-body-text-list c-faq-reorganize__list-subbody-content-desc-paddingBottom20"><span class="c-faq-reorganize__list-subbody-content-desc_bold">For users who have <span class="c-faq-reorganize__list-subbody-content-desc_red">not activated</span> their Two-Factor Authentication Code (2FA).</span><br/>
                      A whitelist request email will be sent to the email address you signed up with. Verify the request by
                      clicking on the link in the email.
                      </p>
                    </div>
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number">8.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom40">For <span class="c-faq-reorganize__list-subbody-content-desc_bold">private wallet</span>, the verification process will require you to send a small test deposit from the private
                      wallet of your choice to our designated deposit address. Upon validation completion, Sparrow will credit
                      the test deposit amount and blockchain transaction fees to your Sparrow account.</p>
                    </div>

                    <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-Deposit-and-Withdrawal-6.png">
                    <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      <p class="c-faq-reorganize__list-body-number">9.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom70">Once you have clicked on the link in the email, you can view the status of your verification process. Your
                      wallet is <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">successfully whitelisted once the status shows available in green</span>.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-faq-reorganize__list-body-content-list">
          <div class="c-faq-reorganize__list-body-content-list-title-wrapper" @click="toggleBody(2)">
            <p class="c-faq-reorganize__list-body-content-list-title">Why am I required to go through multiple notices and disclaimers?</p>
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[2].clicked">
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-min.svg" v-else>
          </div>
          <div class="c-faq-reorganize__list-subbody" :class="{'c-faq-reorganize__open-child': statusBody[2].clicked, 'c-faq-reorganize__list-subbody_active' : statusBody[2].clicked}">
            <div class="c-faq-reorganize__list-subbody-content c-faq-reorganize__list-subbody-content-no-paddingbot" :class="{'c-faq-reorganize__list-subbody-content_inactive': statusBody[2].statusSubBody[0].clicked == false && statusBody[2].statusSubBody[1].clicked == false}">
                <p class="c-faq-reorganize__list-subbody-content-desc">The Monetary Authority of Singapore (MAS) requires digital payment token service providers to provide specific risk warnings to clients. These ensure our clients are kept well-informed of changes to the business without disrupting your activities on Sparrow. </p>

                <div class="c-faq-reorganize__list-subbody-content-child">
                <div class="c-faq-reorganize__list-subbody-content-child-title-wrapper" @click="toggleSubBody(2,0)">
                  <ul class="c-faq-reorganize__list-subbody-content-unorderlist" :class="{'c-faq-reorganize__list-subbody-content-unorderlist-border': statusBody[2].statusSubBody[0].clicked}">
                    <li class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_last">Why do I need to accept a revised set of T&C and privacy statement?</li>
                  </ul>
                  <!-- <p class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_first" :class="{'c-faq-reorganize__list-subbody-content-child-title_last': !statusBody[2].statusSubBody[0].clicked}">• Why do I need to accept a revised set of T&C and privacy statement? </p> -->
                  <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[2].statusSubBody[0].clicked">
                  <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-min.svg" v-else>
                </div>
                <div class="c-faq-reorganize__list-subbody-content-child-list" :class="{'c-faq-reorganize__open-grandchild': statusBody[2].statusSubBody[0].clicked, 'c-faq-reorganize__list-subbody-content-child-list_active' : statusBody[2].statusSubBody[0].clicked}">
                  <p class="c-faq-reorganize__list-subbody-content-desc c-faq-reorganize__list-subbody-content-desc_paddingTop20 c-faq-reorganize__list-subbody-content-child-title_last">
                    As the regulated activities under the Payment Services Act and activities which are not subject of the Payment Services Act will be offered by different Sparrow entities, updated terms and conditions are necessary to govern the terms of your use and offer of products and services by the relevant Sparrow entities. However, regardless of the changes in structure, Sparrow as a Group will continue to offer great products and services always.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-faq-reorganize__list-body-content-list">
          <div class="c-faq-reorganize__list-body-content-list-title-wrapper" @click="toggleBody(3)">
            <p class="c-faq-reorganize__list-body-content-list-title ">How do you ensure that the reorganization is adequate and complied with?</p>
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[3].clicked">
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-min.svg" v-else>
          </div>
          <div class="c-faq-reorganize__list-subbody c-faq-reorganize__list-subbody-content-child-title_last" :class="{'c-faq-reorganize__open-child': statusBody[3].clicked, 'c-faq-reorganize__list-subbody_active' : statusBody[3].clicked}">
            <div class="c-faq-reorganize__list-subbody-content c-faq-reorganize__list-subbody-content-no-paddingbot c-faq-reorganize__list-subbody-content-child-title_last" :class="{'c-faq-reorganize__list-subbody-content_inactive': statusBody[3].statusSubBody[0].clicked == false && statusBody[3].statusSubBody[1].clicked == false}">
                <p class="c-faq-reorganize__list-subbody-content-desc c-faq-reorganize__list-subbody-content-no-paddingbot c-faq-reorganize__list-subbody-content-child-title_last">Sparrow has a team of experienced professionals and robust processes to ensure that the reorganization is conducted professionally. We regularly keep the Monetary Authority of Singapore (MAS) informed of our plans and will continue to do so through this transition.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      headClicked: false,
      statusBody: [
        {clicked: false},
        {clicked: false, statusSubBody: [{clicked: false}, {clicked: false}]},
        {clicked: false, statusSubBody: [{clicked: false}, {clicked: false}]},
        {clicked: false, statusSubBody: [{clicked: false}, {clicked: false}]},
        {clicked: false},
      ]
    }
  },
  mounted() {

  },
  methods: {
    toggleHead() {
      let _this = this;
      this.headClicked = !this.headClicked
      this.$nextTick(()=>{
        _this.adjustHeight(0, -1, -1);
      })
    },
    toggleBody(index) {
      let _this = this;
      this.statusBody[index].clicked = !this.statusBody[index].clicked
      this.$nextTick(()=>{
        _this.adjustHeight(0, index, -1);
      })
    },
    toggleSubBody(idxBody, idxSub) {
      let _this = this;
      this.statusBody[idxBody].statusSubBody[idxSub].clicked = !this.statusBody[idxBody].statusSubBody[idxSub].clicked
      this.$nextTick(()=>{
        _this.adjustHeight(0, idxBody, idxSub);
      })
    },
    adjustHeight:function(idx, idxChild, idxGrandChild){
      let grandChildElements, childElements, bodyElements
      bodyElements = document.getElementsByClassName('c-faq-reorganize__list-body')
      if(idxGrandChild>=0){
        childElements = bodyElements[idx].getElementsByClassName('c-faq-reorganize__list-subbody')
        grandChildElements = childElements[idxChild].getElementsByClassName('c-faq-reorganize__list-subbody-content-child-list')
        if(grandChildElements[idxGrandChild].classList.contains('c-faq-reorganize__open-grandchild')){
          let grandChildBodyHeight = grandChildElements[idxGrandChild].scrollHeight + 90
          grandChildElements[idxGrandChild].style.maxHeight = grandChildBodyHeight+'px'
          this.scrollToFAQ(grandChildElements[idxGrandChild].offsetTop);
        }
        else{
          grandChildElements[idxGrandChild].style.maxHeight ='0px'
        }
        childElements[idxChild].style.maxHeight = '9999px'
        bodyElements[idx].style.maxHeight = '9999px'
      }
      else{
        if(idxChild>=0){
          childElements = bodyElements[idx].getElementsByClassName('c-faq-reorganize__list-subbody')
          if(childElements[idxChild].classList.contains('c-faq-reorganize__open-child')){
            let childBodyHeight = childElements[idxChild].scrollHeight + 90
            childElements[idxChild].style.maxHeight = childBodyHeight+'px'
            this.scrollToFAQ(childElements[idxChild].offsetTop);
          }
          else{
            childElements[idxChild].style.maxHeight = '0px'
          }
          bodyElements[idx].style.maxHeight = '9999px'
        }
        else{
          if(bodyElements[idx].classList.contains('c-faq-reorganize__list-body_active')){
            let bodyHeight = bodyElements[idx].scrollHeight + 90
            bodyElements[idx].style.maxHeight = bodyHeight+'px'
            this.scrollToFAQ(bodyElements[idx].offsetTop);
          }
          else{
            bodyElements[idx].style.maxHeight = '0px'
          }
        }
      }
    },
    scrollToFAQ(position) {
      $('html,body').animate({scrollTop: position - 170},'slow');
    }
  }
}
</script>

<template>
  <div class="c-faq">
    <div class="c-faq__title c-faq__title_reorganize">
      <h2 class="c-faq__title-text-second">What you need to know about</h2>
      <h1 class="c-faq__title-text-titletext">The Ethereum Merge</h1>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faqList: []
    }
  },
  mounted() {

  },
  methods: {

  },
}
</script>

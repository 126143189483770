<template>
  <div class="c-faq-reorganize__list">
    <div class="c-faq-reorganize__list-head" @click="toggleHead()">
      <p class="c-faq-reorganize__list-head-text">Deposit and Withdrawal Process</p>
      <img class="c-faq-reorganize__list-head-img" src="@/assets/images/arrow-down.svg" v-if="!headClicked">
      <img class="c-faq-reorganize__list-head-img" src="@/assets/images/arrow-up.svg" v-else>
    </div>
    <div class="c-faq-reorganize__list-body" :class="{'c-faq-reorganize__list-body_active': headClicked}">
      <div class="c-faq-reorganize__list-body-content" :class="{'c-faq-reorganize__open-body': headClicked}">

        <div class="c-faq-reorganize__list-body-content-list">
          <div class="c-faq-reorganize__list-body-content-list-title-wrapper" @click="toggleBody(0)">
            <p class="c-faq-reorganize__list-body-content-list-title">What is the value transfer all about?</p>
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[0].clicked">
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-min.svg" v-else>
          </div>
          <div class="c-faq-reorganize__list-subbody c-faq-reorganize__list-subbody-content-child-title_last" :class="{'c-faq-reorganize__open-child': statusBody[0].clicked, 'c-faq-reorganize__list-subbody_active' : statusBody[0].clicked}">
            <div class="c-faq-reorganize__list-subbody-content c-faq-reorganize__list-subbody-content-no-paddingbot" :class="{'c-faq-reorganize__list-subbody-content_inactive': statusBody[0].statusSubBody[0].clicked == false && statusBody[0].statusSubBody[0].clicked == false}">
              <p class="c-faq-reorganize__list-subbody-content-desc">As part of the reorganizing and to enhance compliance standards, you will need to whitelist your wallets to deposit and withdraw digital assets on Sparrow. For private unhosted wallets, you must perform a Satoshi Test on the wallets.</p>

              <div class="c-faq-reorganize__list-subbody-content-child">
                <div class="c-faq-reorganize__list-subbody-content-child-title-wrapper" @click="toggleSubBody(0,0)">
                  <ul class="c-faq-reorganize__list-subbody-content-unorderlist c-faq-reorganize__list-subbody-content-unorderlist-border">
                    <li class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_last">How to whitelist your wallet and perform a mandatory Satoshi Test for private wallets.</li>
                  </ul>
                  <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[0].statusSubBody[0].clicked">
                  <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-min.svg" v-else>
                </div>
                <div class="c-faq-reorganize__list-subbody-content-child-list" :class="{'c-faq-reorganize__open-grandchild': statusBody[0].statusSubBody[0].clicked, 'c-faq-reorganize__list-subbody-content-child-list_active' : statusBody[0].statusSubBody[0].clicked}">
                  <div class="c-faq-reorganize__list-subbody-content-child-list-desc">
                    <img src="@/assets/images/FAQ-Deposit-and-Withdrawal-1.png" alt="" class="c-faq-reorganize__list-body-margintop20 c-faq-reorganize__list-subbody-content-child-list-img">
                    <div class="c-faq-reorganize__list-body-margintop20">
                      <p class="c-faq-reorganize__list-body-number">1.</p>
                      <p class="c-faq-reorganize__list-body-text">Log into your Sparrow’s account and and click on the ‘<span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Funds</span>’ tab.</p>
                    </div>
                    <div>
                      <p class="c-faq-reorganize__list-body-number">2.</p>
                      <p class="c-faq-reorganize__list-body-text">Choose either <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">deposit or withdrawal on the navigation bar on the left</span>.</p>
                    </div>
                    <div>
                      <p class="c-faq-reorganize__list-body-number">3. </p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-body-marginbottom40">Under the deposit from section, <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">click on select</span> and a ‘Select BTC Address’ pop-up will appear.</p>
                    </div>

                    <img src="@/assets/images/FAQ-Deposit-and-Withdrawal-2.png" alt="" class="c-faq-reorganize__list-subbody-content-child-list-img">
                    <div class="c-faq-reorganize__list-body-margintop20">
                      <p class="c-faq-reorganize__list-body-number">4.</p>
                      <p class="c-faq-reorganize__list-body-text">For <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">exchange wallet</span>, choose from one of the selections under the dropdown and label it accordingly.</p>
                    </div>
                    <div>
                      <p class="c-faq-reorganize__list-body-number">5.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-body-marginbottom40">For <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">private wallet</span>, choose from one of the selections under the dropdown, label it accordingly, and input your wallet address.</p>
                    </div>
                    <img src="@/assets/images/FAQ-Deposit-and-Withdrawal-3.png" alt="" class="c-faq-reorganize__list-subbody-content-child-list-img">
                    <div class="c-faq-reorganize__list-body-margintop20">
                      <p class="c-faq-reorganize__list-body-number">6.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-body-marginbottom40">Your ‘<span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Name</span>’, and ‘<span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Date of Birth</span>’ will be auto-populated. Simply <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">click on the ‘Add’ button</span>.</p>
                    </div>
                    <img src="@/assets/images/FAQ-Deposit-and-Withdrawal-4.png" alt="" class="c-faq-reorganize__list-subbody-content-child-list-img">
                    <div class="c-faq-reorganize__list-body-margintop20">
                      <p class="c-faq-reorganize__list-body-number c-faq-reorganize__list-body-number-list">7a.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-body-text-list c-faq-reorganize__list-body-marginbottom40">You will be prompted to enter your Two-Factor Authentication Code (2FA).</p>
                    </div>
                    <img src="@/assets/images/FAQ-Deposit-and-Withdrawal-5.png" alt="" class="c-faq-reorganize__list-subbody-content-child-list-img">
                    <div class="c-faq-reorganize__list-body-margintop20">
                      <p class="c-faq-reorganize__list-body-number c-faq-reorganize__list-body-number-list">7b.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-body-text-list"><span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">For users who have</span><span class="c-faq-reorganize__list-subbody-content-child-list-desc-red-color c-faq-reorganize__list-subbody-content-child-list-desc-extrabold"> not activated </span><span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Two-Factor Authentication Code (2FA)</span>.</p>
                      <p class="c-faq-reorganize__list-body-text-alone">A whitelist request email will be sent to the email address you signed up with. Verify the request by clicking on the link in the email.</p>
                    </div>

                    <div class="c-faq-reorganize__list-body-margintop20">
                      <p class="c-faq-reorganize__list-body-number">8.</p>
                      <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-body-marginbottom40"> For <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">private wallet</span>, the verification process will require you to send a small test deposit from the private wallet of your choice to our designated deposit address. Upon validation completion, Sparrow will credit the test deposit amount and blockchain transaction fees to your Sparrow account.</p>
                    </div>
                    <img src="@/assets/images/FAQ-Deposit-and-Withdrawal-6.png" alt="" class="c-faq-reorganize__list-subbody-content-child-list-img">
                    <div class="c-faq-reorganize__list-body-margintop20">
                      <p class="c-faq-reorganize__list-body-number">9.</p>
                      <p class="c-faq-reorganize__list-body-text">Once you have clicked on the link in the email, you can view the status of your verification process. Your wallet is <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">successfully whitelisted once the status shows available in green</span>.</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      headClicked: false,
      statusBody: [
        {clicked: false, statusSubBody: [{clicked: false}]},
      ]
    }
  },
  mounted() {

  },
  methods: {
    toggleHead() {
      let _this = this;
      this.headClicked = !this.headClicked
      this.$nextTick(()=>{
        _this.adjustHeight(4, -1, -1);
      })
    },
    toggleBody(index) {
      let _this = this;
      this.statusBody[index].clicked = !this.statusBody[index].clicked
      this.$nextTick(()=>{
        _this.adjustHeight(4, index, -1);
      })
    },
    toggleSubBody(idxBody, idxSub) {
      let _this = this;
      this.statusBody[idxBody].statusSubBody[idxSub].clicked = !this.statusBody[idxBody].statusSubBody[idxSub].clicked
      this.$nextTick(()=>{
        _this.adjustHeight(4, idxBody, idxSub);
      })
    },
    adjustHeight:function(idx, idxChild, idxGrandChild){
      let grandChildElements, childElements, bodyElements
      bodyElements = document.getElementsByClassName('c-faq-reorganize__list-body')
      if(idxGrandChild>=0){
        childElements = bodyElements[idx].getElementsByClassName('c-faq-reorganize__list-subbody')
        grandChildElements = childElements[idxChild].getElementsByClassName('c-faq-reorganize__list-subbody-content-child-list')
        if(grandChildElements[idxGrandChild].classList.contains('c-faq-reorganize__open-grandchild')){
          let grandChildBodyHeight = grandChildElements[idxGrandChild].scrollHeight + 90
          grandChildElements[idxGrandChild].style.maxHeight = grandChildBodyHeight+'px'
          this.scrollToFAQ(grandChildElements[idxGrandChild].offsetTop);
        }
        else{
          grandChildElements[idxGrandChild].style.maxHeight ='0px'
        }
        childElements[idxChild].style.maxHeight = '9000px'
        bodyElements[idx].style.maxHeight = '9999px'
      }
      else{
        if(idxChild>=0){
          childElements = bodyElements[idx].getElementsByClassName('c-faq-reorganize__list-subbody')
          if(childElements[idxChild].classList.contains('c-faq-reorganize__open-child')){
            let childBodyHeight = childElements[idxChild].scrollHeight + 90
            childElements[idxChild].style.maxHeight = childBodyHeight+'px'
            this.scrollToFAQ(childElements[idxChild].offsetTop);
          }
          else{
            childElements[idxChild].style.maxHeight = '0px'
          }
          bodyElements[idx].style.maxHeight = '9999px'
        }
        else{
          if(bodyElements[idx].classList.contains('c-faq-reorganize__list-body_active')){
            let bodyHeight = bodyElements[idx].scrollHeight + 90
            bodyElements[idx].style.maxHeight = bodyHeight+'px'
            this.scrollToFAQ(bodyElements[idx].offsetTop);
          }
          else{
            bodyElements[idx].style.maxHeight = '0px'
          }
        }
      }
    },
    scrollToFAQ(position) {
      $('html,body').animate({scrollTop: position - 170},'slow');
    }
  }
}
</script>

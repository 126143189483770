<template>
  <div class="c-faq-reorganize__list">
    <div class="c-faq-reorganize__list-head" @click="toggleHead()">
      <p class="c-faq-reorganize__list-head-text">Services and Solutions</p>
      <img class="c-faq-reorganize__list-head-img" src="@/assets/images/arrow-down.svg" v-if="!headClicked">
      <img class="c-faq-reorganize__list-head-img" src="@/assets/images/arrow-up.svg" v-else>
    </div>
    <div class="c-faq-reorganize__list-body" :class="{'c-faq-reorganize__list-body_active': headClicked}">
      <div class="c-faq-reorganize__list-body-content" :class="{'c-faq-reorganize__open-body': headClicked}">
        <div class="c-faq-reorganize__list-body-content-list">
          <div class="c-faq-reorganize__list-body-content-list-title-wrapper" @click="toggleBody(0)" >
            <p class="c-faq-reorganize__list-body-content-list-title">How will this affect the design of digital asset products at Sparrow?</p>
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[0].clicked">
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-min.svg" v-else>
          </div>
          <div class="c-faq-reorganize__list-subbody" :class="{'c-faq-reorganize__open-child': statusBody[0].clicked, 'c-faq-reorganize__list-subbody_active' : statusBody[0].clicked}">
            <div class="c-faq-reorganize__list-subbody-content">
              <div class="c-faq-reorganize__list-subbody-content-desc c-faq-reorganize__list-subbody-content-child-title_last">
                Digital assets derivatives products and services will be offered by Sparrow Digital. Please contact your Relationship Manager for more details.
              </div>
            </div>
          </div>
        </div>
        <div class="c-faq-reorganize__list-body-content-list">
          <div class="c-faq-reorganize__list-body-content-list-title-wrapper" @click="toggleBody(1)" >
            <p class="c-faq-reorganize__list-body-content-list-title">Are there any changes to the deposit, transaction, and withdrawal fees?</p>
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[1].clicked">
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-min.svg" v-else>
          </div>
          <div class="c-faq-reorganize__list-subbody" :class="{'c-faq-reorganize__open-child': statusBody[1].clicked, 'c-faq-reorganize__list-subbody_active' : statusBody[1].clicked}">
            <div class="c-faq-reorganize__list-subbody-content">
              <div class="c-faq-reorganize__list-subbody-content-desc c-faq-reorganize__list-subbody-content-child-title_last">
                There will be no changes to the fees for the following products and services:
                <div class="c-faq-reorganize__list-subbody-content-desc-list">
                  <ul class="c-faq-reorganize__list-subbody-content-unorderlist">
                    <li>ConvertNOW</li>
                    <li>Deposit</li>
                    <li>Withdrawal</li>
                    <li>Sparrow Options</li>
                  </ul>
                </div>
                <span class="c-faq-reorganize__list-subbody-content-desc_bold c-faq-reorganize__list-subbody-content-desc_paddingTop20">Please note:</span> There may be transaction fees incurred due to use of the blockchain or when performing a Satoshi Test. Sparrow will credit the test deposit amount and blockchain transaction fees to your Sparrow Account upon completion.
              </div>
            </div>
          </div>
        </div>
        <div class="c-faq-reorganize__list-body-content-list">
          <div class="c-faq-reorganize__list-body-content-list-title-wrapper" @click="toggleBody(2)" >
            <p class="c-faq-reorganize__list-body-content-list-title">Will there be any changes to the design of products and services offered?
            </p>
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[2].clicked">
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-min.svg" v-else>
          </div>
          <div class="c-faq-reorganize__list-subbody" :class="{'c-faq-reorganize__open-child': statusBody[2].clicked, 'c-faq-reorganize__list-subbody_active' : statusBody[2].clicked}">
            <div class="c-faq-reorganize__list-subbody-content c-faq-reorganize__list-subbody-content-no-paddingbot" :class="{'c-faq-reorganize__list-subbody-content_inactive': statusBody[2].statusSubBody[0].clicked == false && statusBody[2].statusSubBody[1].clicked == false}">
                <div class="c-faq-reorganize__list-subbody-content-desc">
                  There will be no changes to the fees for the following products and services:
                  <div class="c-faq-reorganize__list-subbody-content-desc-list">
                    <ul class="c-faq-reorganize__list-subbody-content-unorderlist">
                      <li>ConvertNOW</li>
                      <li>Deposit</li>
                      <li>Withdrawal</li>
                      <li>Sparrow Options</li>
                    </ul>
                  </div>
                For other products and services, please contact your <span class="c-faq-list__body-content-text_url"><a href="/contact-us/reach-out" target="_blank">Relationship Manager</a></span>.
                </div>
                <div class="c-faq-reorganize__list-subbody-content-child">
                  <div class="c-faq-reorganize__list-subbody-content-child-title-wrapper" @click="toggleSubBody(2,0)">
                    <ul class="c-faq-reorganize__list-subbody-content-unorderlist c-faq-reorganize__list-subbody-content-unorderlist-border">
                      <li class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_last">Are options trading regulated at Sparrow in Singapore?</li>
                    </ul>
                    <!-- <p class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_first">• Are options trading regulated at Sparrow in Singapore?</p> -->
                    <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[2].statusSubBody[0].clicked">
                    <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-min.svg" v-else>
                  </div>
                  <div class="c-faq-reorganize__list-subbody-content-child-list" :class="{'c-faq-reorganize__open-grandchild': statusBody[2].statusSubBody[0].clicked, 'c-faq-reorganize__list-subbody-content-child-list_active' : statusBody[2].statusSubBody[0].clicked}">
                    <div class="c-faq-reorganize__list-subbody-content-desc c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      Sparrow Options are not currently regulated under the Payment Services Act.
                    </div>
                  </div>
                </div>
                <div class="c-faq-reorganize__list-subbody-content-child">
                  <div class="c-faq-reorganize__list-subbody-content-child-title-wrapper" @click="toggleSubBody(2,1)">
                    <ul class="c-faq-reorganize__list-subbody-content-unorderlist c-faq-reorganize__list-subbody-content-unorderlist-border">
                      <li class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_last">What other products or services are not regulated at Sparrow?</li>
                    </ul>
                    <!-- <p class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_first">• What other products or services are not regulated at Sparrow?</p> -->
                    <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[2].statusSubBody[1].clicked">
                    <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-min.svg" v-else>
                  </div>
                  <div class="c-faq-reorganize__list-subbody-content-child-list" :class="{'c-faq-reorganize__open-grandchild': statusBody[2].statusSubBody[1].clicked, 'c-faq-reorganize__list-subbody-content-child-list_active' : statusBody[2].statusSubBody[1].clicked}">
                    <div class="c-faq-reorganize__list-subbody-content-desc c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      Products and services not currently regulated under the Payment Services Act:

                      <div class="c-faq-reorganize__list-subbody-content-desc-list">
                        <ul class="c-faq-reorganize__list-subbody-content-unorderlist">
                          <li>Sparrow Options</li>
                          <li>Derivatives products and services (For more information, please contact your <span class="c-faq-list__body-content-text_url"><a href="/contact-us/reach-out" target="_blank">Relationship Manager</a></span>)</li>
                        </ul>
                    </div>
                    </div>
                  </div>
                </div>
                <div class="c-faq-reorganize__list-subbody-content-child">
                  <div class="c-faq-reorganize__list-subbody-content-child-title-wrapper" @click="toggleSubBody(2,2)">
                    <ul class="c-faq-reorganize__list-subbody-content-unorderlist c-faq-reorganize__list-subbody-content-unorderlist-border">
                      <li class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_last">What products and services are regulated at Sparrow?</li>
                    </ul>
                    <!-- <p class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_first">• What products and services are regulated at Sparrow? </p> -->
                    <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[2].statusSubBody[2].clicked">
                    <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-min.svg" v-else>
                  </div>
                  <div class="c-faq-reorganize__list-subbody-content-child-list" :class="{'c-faq-reorganize__open-grandchild': statusBody[2].statusSubBody[2].clicked, 'c-faq-reorganize__list-subbody-content-child-list_active' : statusBody[2].statusSubBody[2].clicked}">
                    <div class="c-faq-reorganize__list-subbody-content-desc c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                      Products and services regulated under the Payment Services Act:
                      <div class="c-faq-reorganize__list-subbody-content-desc-list">
                        <ul class="c-faq-reorganize__list-subbody-content-unorderlist">
                          <li>ConvertNOW</li>
                          <li>Deposit</li>
                          <li>Withdrawal</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="c-faq-reorganize__list-subbody-content-child">
                  <div class="c-faq-reorganize__list-subbody-content-child-title-wrapper" @click="toggleSubBody(2,3)">
                    <ul class="c-faq-reorganize__list-subbody-content-unorderlist" :class="{'c-faq-reorganize__list-subbody-content-unorderlist-border': statusBody[2].statusSubBody[3].clicked}">
                      <li class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_last">How do I go about opting in and out of the product features setting?</li>
                    </ul>
                    <!-- <p class="c-faq-reorganize__list-subbody-content-child-title c-faq-reorganize__list-subbody-content-child-title_first" :class="{'c-faq-reorganize__list-subbody-content-child-title_last': !statusBody[2].statusSubBody[3].clicked}">• How do I go about opting in and out of the product features setting?</p> -->
                    <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[2].statusSubBody[3].clicked">
                    <img class="c-faq-reorganize__list-subbody-content-child-title-img" src="@/assets/images/icon-min.svg" v-else>
                  </div>
                  <div class="c-faq-reorganize__list-subbody-content-child-list" :class="{'c-faq-reorganize__open-grandchild': statusBody[2].statusSubBody[3].clicked, 'c-faq-reorganize__list-subbody-content-child-list_active' : statusBody[2].statusSubBody[3].clicked}">
                    <div class="c-faq-reorganize__list-subbody-content-desc c-faq-reorganize__list-subbody-content-desc_paddingTop20 c-faq-reorganize__list-subbody-content-child-title_last">
                      You may wish to opt in and out of the non-regulated entities for options. Do note for ConvertNOW, it will be displayed as by default in your Account. Should you wish to activate or view details pertaining to other Sparrow products and services including Structured Products, you may contact your respective Relationship Managers for support.
                    </div>
                    <div>
                      <div class="c-faq-reorganize__list-subbody-content-child-list-desc">
                        <div>
                          <p class="c-faq-reorganize__list-body-number">1.</p>
                          <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom20">Log into your Sparrow’s account.</p>
                        </div>
                        <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-opt-product-1.png">
                        <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                          <p class="c-faq-reorganize__list-body-number">2.</p>
                          <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom20">A ‘<span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Terms of Use & Privacy and Data protection Policy</span>’ will appear once.</p>
                        </div>
                        <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-opt-product-2.png">
                        <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20">
                          <p class="c-faq-reorganize__list-body-number">3.</p>
                          <p class="c-faq-reorganize__list-body-text c-faq-reorganize__list-subbody-content-desc-paddingBottom20">Click on <span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Accept & Continue</span>.</p>
                        </div>
                        <img class="c-faq-reorganize__list-subbody-content-child-list-img" src="@/assets/images/FAQ-opt-product-3.png">
                        <div class="c-faq-reorganize__list-subbody-content-desc_paddingTop20 c-faq-reorganize__list-subbody-content-desc-paddingBottom70">
                          <p class="c-faq-reorganize__list-body-number">4.</p>
                          <p class="c-faq-reorganize__list-body-text">Under the ‘<span class="c-faq-reorganize__list-subbody-content-child-list-desc-extrabold">Account</span>’ tab, you can view all your products and toggle between opt-in or opt-up on the slider bar.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      headClicked: false,
      statusBody: [
        {clicked: false},
        {clicked: false, statusSubBody: [{clicked: false}, {clicked: false}]},
        {clicked: false, statusSubBody: [{clicked: false}, {clicked: false}, {clicked: false}, {clicked: false}]},
        {clicked: false, statusSubBody: [{clicked: false}, {clicked: false}]},
        {clicked: false},
      ]
    }
  },
  mounted() {

  },
  methods: {
    toggleHead() {
      let _this = this;
      this.headClicked = !this.headClicked
      this.$nextTick(()=>{
        _this.adjustHeight(2, -1, -1);
      })
    },
    toggleBody(index) {
      let _this = this;
      this.statusBody[index].clicked = !this.statusBody[index].clicked
      this.$nextTick(()=>{
        _this.adjustHeight(2, index, -1);
      })
    },
    toggleSubBody(idxBody, idxSub) {
      let _this = this;
      this.statusBody[idxBody].statusSubBody[idxSub].clicked = !this.statusBody[idxBody].statusSubBody[idxSub].clicked
      this.$nextTick(()=>{
        _this.adjustHeight(2, idxBody, idxSub);
      })
    },
    adjustHeight:function(idx, idxChild, idxGrandChild){
      let grandChildElements, childElements, bodyElements
      bodyElements = document.getElementsByClassName('c-faq-reorganize__list-body')
      if(idxGrandChild>=0){
        childElements = bodyElements[idx].getElementsByClassName('c-faq-reorganize__list-subbody')
        grandChildElements = childElements[idxChild].getElementsByClassName('c-faq-reorganize__list-subbody-content-child-list')
        if(grandChildElements[idxGrandChild].classList.contains('c-faq-reorganize__open-grandchild')){
          let grandChildBodyHeight = grandChildElements[idxGrandChild].scrollHeight + 90
          grandChildElements[idxGrandChild].style.maxHeight = grandChildBodyHeight+'px'
          this.scrollToFAQ(grandChildElements[idxGrandChild].offsetTop);
        }
        else{
          grandChildElements[idxGrandChild].style.maxHeight ='0px'
        }
        childElements[idxChild].style.maxHeight = '9999px'
        bodyElements[idx].style.maxHeight = '9999px'
      }
      else{
        if(idxChild>=0){
          childElements = bodyElements[idx].getElementsByClassName('c-faq-reorganize__list-subbody')
          if(childElements[idxChild].classList.contains('c-faq-reorganize__open-child')){
            let childBodyHeight = childElements[idxChild].scrollHeight + 90
            childElements[idxChild].style.maxHeight = childBodyHeight+'px'
            this.scrollToFAQ(childElements[idxChild].offsetTop);
          }
          else{
            childElements[idxChild].style.maxHeight = '0px'
          }
          bodyElements[idx].style.maxHeight = '9999px'
        }
        else{
          if(bodyElements[idx].classList.contains('c-faq-reorganize__list-body_active')){
            let bodyHeight = bodyElements[idx].scrollHeight + 90
            bodyElements[idx].style.maxHeight = bodyHeight+'px'
            this.scrollToFAQ(bodyElements[idx].offsetTop);
          }
          else{
            bodyElements[idx].style.maxHeight = '0px'
          }
        }
      }
    },
    scrollToFAQ(position) {
      $('html,body').animate({scrollTop: position - 170},'slow');
    }
  }
}
</script>

<template>
  <div class = "c-faq-list">
  <div class = "c-faq-list__container">
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click="toggleActive(0)">
        <p class = "c-faq-list__title">What is The Merge?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqEMList[0].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__em-body" :class="{'c-faq-list__em-body_active':activeFaqEMList[0].status == true}">
        <div class="c-faq-list__body-content">
          <p class = "c-faq-list__body-content-text">
          The Merge is a transition from a Proof-of-Work (PoW) consensus network to a Proof-of-Stake (PoS) consensus network. This essentially means that Ethereum no longer will be “mined” to secure the network but rather, validators secure the network by staking and collateralizing ETH.
          </p>
        </div>
      </div>
    </div>
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(1)">
        <p class = "c-faq-list__title">When is The Merge happening?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqEMList[1].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__em-body" :class="{'c-faq-list__em-body_active':activeFaqEMList[1].status == true}">
        <div class="c-faq-list__body-content">
          <p class = "c-faq-list__body-content-text">
          The Merge is scheduled to take place between 6 and 20 September 2022.</p>
        </div>
      </div>
    </div>
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(2)">
        <p class = "c-faq-list__title">What do I need to do to prepare for The Merge?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqEMList[2].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__em-body" :class="{'c-faq-list__em-body_active':activeFaqEMList[2].status == true}">
        <div class = "c-faq-list__body-content">
        <p class = "c-faq-list__body-content-text">
       <ol class="c-faq-list__em-orderlist">
            <li class = "c-faq-list__body-content-text2">No action is required from Sparrow clients.</li>
            <li class = "c-faq-list__body-content-text2">Sparrow will support the new Proof of Stake (PoS) chain as the consensus layer for its Ethereum wallets.</li>
            <li class = "c-faq-list__body-content-text">If there is an unplanned fork, Sparrow will evaluate the viability and liquidity, among other factors, to determine the feasibility of supporting any ETH-PoW chains and inform our clients accordingly.</li>
          </ol>
       </p>
        </div>
      </div>
    </div>
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(3)">
        <p class = "c-faq-list__title">During the upgrade, can I continue to use the platform to enter into trades that have Ethereum pairing?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqEMList[3].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__em-body" :class="{'c-faq-list__em-body_active':activeFaqEMList[3].status == true}">
        <div class="c-faq-list__body-content">
          <p class = "c-faq-list__body-content-text">
          You can continue to use the platform to enter into trades that have Ethereum pairing during the upgrade.
          </p>
          
        </div>
      </div>
    </div>
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(4)">
        <p class = "c-faq-list__title">Is it safe to keep my Ethereum assets in my Sparrow account during The Merge?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqEMList[4].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__em-body" :class="{'c-faq-list__em-body_active':activeFaqEMList[4].status == true}">
        <div class = "c-faq-list__body-content">
          <p class = "c-faq-list__body-content-text">Your assets remain safe with us during The Merge.</p>
        </div>
      </div>
    </div>
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(5)">
        <p class = "c-faq-list__title">If I have any questions regarding The Merge, who should I contact?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqEMList[5].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__em-body" :class="{'c-faq-list__em-body_active':activeFaqEMList[5].status == true}">
        <div class = "c-faq-list__body-content">
          <p class = "c-faq-list__body-content-text">If you have any questions, please reach out to your Relationship Manager or submit a request at <span class="c-faq-list__body-content-text_url" @click="openLink('https://sparrowexchange.com/support/sparrowcares')">Sparrow Cares</span></p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import $ from 'jquery'
export default {
  name: 'FAQ',
  data() {
    return {
      activeFaq: -1,
      offsetList: [],
      statusOpened: false,
      activeFaqEMList: [
        {status:false},
        {status:false},
        {status:false},
        {status:false},
        {status:false},
        {status:false},
      ]
    }
  },
  methods: {
    toggleActive:function(idx){
      let _this = this;
      if(this.activeFaqEMList[idx].status == true) {
        this.activeFaqEMList[idx].status = false
        this.statusOpened = false
      } else {
        this.statusOpened = true
        this.activeFaqEMList[idx].status = true
      }
      this.$nextTick(()=>{
        _this.adjustHeight(idx);
      })
    },
    adjustHeight:function(idx){
      let bodyElements = document.getElementsByClassName('c-faq-list__em-body')
      if(bodyElements[idx].classList.contains('c-faq-list__em-body_active')){
        let bodyHeight = bodyElements[idx].scrollHeight + 70
        bodyElements[idx].style.maxHeight = bodyHeight+'px'
        this.scrollToFAQ(bodyElements[idx].offsetTop);
      }
      else{
        bodyElements[idx].style.maxHeight = '0px'
      }
    },
    scrollToFAQ(position) {
      $('html,body').animate({scrollTop: position - 170},'slow');
    }
  },
}
</script>

<template>
  <div class="c-faq-reorganize__list">
    <div class="c-faq-reorganize__list-head" @click="toggleHead()">
      <p class="c-faq-reorganize__list-head-text">Troubleshoots and Support</p>
      <img class="c-faq-reorganize__list-head-img" src="@/assets/images/arrow-down.svg" v-if="!headClicked">
      <img class="c-faq-reorganize__list-head-img" src="@/assets/images/arrow-up.svg" v-else>
    </div>
    <div class="c-faq-reorganize__list-body" :class="{'c-faq-reorganize__list-body_active': headClicked}">
      <div class="c-faq-reorganize__list-body-content" :class="{'c-faq-reorganize__open-body': headClicked}">
        <div class="c-faq-reorganize__list-body-content-list">
          <div class="c-faq-reorganize__list-body-content-list-title-wrapper" @click="toggleBody(0)" >
            <p class="c-faq-reorganize__list-body-content-list-title">Who can I approach if I have more queries or concerns?</p>
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[0].clicked">
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-min.svg" v-else>
          </div>
          <div class="c-faq-reorganize__list-subbody" :class="{'c-faq-reorganize__open-child': statusBody[0].clicked, 'c-faq-reorganize__list-subbody_active' : statusBody[0].clicked}">
            <div class="c-faq-reorganize__list-subbody-content">
              <p>Your feedback and concerns are important to us. Please contact your Relationship Manager or you may submit a <span class="c-faq-list__body-content-text_url" ><router-link to="/support/sparrowcares">support request</router-link></span>, and we will tend to your query soonest.</p>
              <p class="c-faq-reorganize__list-body-margintop20">You may also find more information here:</p>
              <ul class="c-faq-reorganize__list-subbody-content-unorderlist">
                <li>
                  <span class="c-faq-reorganize__list-subbody-content-child-list-desc_link">
                    <a href="/legal/user-agreement" target="_blank">Group Terms and Conditions</a>
                  </span>
                </li>
                <li>
                  <span class="c-faq-reorganize__list-subbody-content-child-list-desc_link">
                    <a href="/legal/privacy-policies" target="_blank">Privacy Policy</a>
                  </span>
                </li>
                <li>
                  <span class="c-faq-reorganize__list-subbody-content-child-list-desc_link">
                    <a href="/legal/disclaimers-and-risk-warning" target="_blank">Legal and Compliance at Sparrow</a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="c-faq-reorganize__list-body-content-list">
          <div class="c-faq-reorganize__list-body-content-list-title-wrapper" @click="toggleBody(1)">
            <p class="c-faq-reorganize__list-body-content-list-title">Who can I approach to learn more about Sparrow’s digital asset products and services?</p>
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-plus.svg" v-if="!statusBody[1].clicked">
            <img class="c-faq-reorganize__list-body-content-list-title-img" src="@/assets/images/icon-min.svg" v-else>
          </div>

          <!-- c-faq-reorganize__list-subbody-no-border-bottom-->
          <div class="c-faq-reorganize__list-subbody" :class="{'c-faq-reorganize__open-child': statusBody[1].clicked, 'c-faq-reorganize__list-subbody_active' : statusBody[1].clicked}">
            <div class="c-faq-reorganize__list-subbody-content"> <!-- c-faq-reorganize__list-subbody-content-no-paddingbot-->
              <p>Sparrow has assembled a team of experts specializing in financial products structuring, trading, treasury management, finance, legal, compliance, technology, people development and operational excellence.</p>
              <p class="c-faq-reorganize__list-body-margintop20">The digital asset solutions team is deeply experienced in product structuring and treasury management. We design and build compelling and relevant products to meet the needs of institutions looking to participate in the digital asset space.</p>
              <p class="c-faq-reorganize__list-body-margintop20">Speak to <a class="c-faq-reorganize__list-body-link" href='https://sparrowexchange.com/contact-us/reach-out/'>a professional at Sparrow</a> today to learn how we can help you build a sustainable portfolio of digital assets.</p>
              
              <!--
              <div class="c-team__container c-faq-reorganize__list-body-margintop20">
                <div class="c-team__item c-team__item-faq" v-for = "(data,idx) in teamData"  :key="idx" @click="showModal(data)">
                  <img :src="data.photo" :alt="data.name" class="c-team__photo">
                  <div class="c-team__info">
                    <h3 class="c-team__name">
                      <span class="c-team__name-text">{{data.name}}</span>
                      <div class = "c-team__arrow-container">
                        <span class = "c-team__arrow">></span>
                      </div>
                    </h3>
                    <p class="c-team__role">{{data.role}}</p>
                  </div>
                </div>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      v-if="isModalVisible"
      @content="userSelected"
      @close="closeModal"
    >
      <template v-slot:name>
        <h2 class="c-about-modal__name">{{userSelected.name}}</h2>
      </template>
      <template v-slot:role>
        <h3 :class="{'c-about-modal__content-short': userSelected.name == 'Jonathan Yu'}">{{userSelected.role}}</h3>
      </template>
      <template v-slot:content>
        <p class="c-about-modal__content" v-html="userSelected.content"></p>
      </template>
    </modal>
  </div>
</template>

<script>
import $ from 'jquery'
import modal from '@/components/about/ModalTeam.vue'
export default {
  components: {
    modal,
  },
  data() {
    return {
      headClicked: false,
      statusBody: [
        {clicked: false},
        {clicked: false},
      ],
      teamData: [
        // {
        //   name:'Ang Kok Wee',
        //   role: 'Chief Revenue Officer',
        //   photo:require('@/assets/images/team/Ang Kok Wee-2.png'),
        //   content: 'With 2 decades of experience in FX derivatives trading, Kok Wee specializes in product structuring and risk management. He is also adept at financial modeling and real estate valuation. Previously with Citigroup, Kok Wee was the head of FX Options Desk, delivering track records in desk profitability and desk expense optimization. In addition, he spearheaded the integration of European, Middle East, and Africa franchises into existing portfolios, and boosted assets under management by 20%. As the Chief Revenue Officer, Kok Wee will oversee the aligned Sales and Trading functions. He aims to synergize the teams to continue to deliver exceptional trade execution standards and client experiences. By constantly enhancing our suite of bespoke digital asset solutions, Kok Wee believes it will help bridge traditional finance and deliver sound portfolio management and diversification solutions to our clients which include institutions, wealth managers and accredited investors.'
        // },
        {
          name:'Tan Ming Hoe',
          role: 'Head of Trading',
          photo:require('@/assets/images/team/Tan Ming Hoe.png'),
          content: 'Ming Hoe has over 13 years of experiences covering areas of FX, equities & commodities trading. Started as a FX Derivatives Dealer with OCBC for 6 years, Ming Hoe then joined numerous family offices to broaden his knowledge of the financial markets while venturing into algorithmic trading. Ming Hoe also accumulated specialization in programming languages including C# and Python. In his academic years, he attained a Merit in MSc Quantitative Finance from CASS Business School London and First-Class Honors in BSc Electronics and Electrical Engineering from University of Edinburgh. As the Head of Trading, Ming Hoe focuses on risk management on Spot and Derivatives flow, ensure optimal execution of trading strategies, and transactions are conducted in accordance with high regulatory compliance.'
        },
        {
          name:'Faith Ng',
          role: 'Deputy Head of Sales (Singapore)',
          photo:require('@/assets/images/team/Faith Ng-2.png'),
          content: 'Faith has over 10 years of professional experience as a relationship manager managing quality account management with leading brands including Standard Chartered Bank and Shangri La Hotel Singapore. Engaged in diverse industries including commercial banking and hospitality, she has work exposures in Australia, China and Indonesia. As the Deputy Head of Sales of the Singapore market, Faith is committed to excelling in what she does, and is dedicated to delivering optimal service standards to partners and key stakeholders, while accelerating business development and revenue growth. '
        },
        {
          name:'Nicolas Rueda',
          role: 'Deputy Head of Sales (Latin America)',
          photo:require('@/assets/images/team/Nicolas Rueda.png'),
          content: 'Passionate about the evolvement of FinTech and digital assets, Nicolas brings with him over 20 years of senior executive leadership specializing in asset and wealth management, investment and corporate banking, and capital markets. Nicolas worked with global financial corporations, such as Morgan Stanley and Deutsche Bank, covering multi-family offices, ultra HNWI and business start-ups. As an experienced professional, he offers extensive product knowledge, broad commercial astuteness, and analytical insights. At Sparrow, Nicolas will be spearheading the Latin America region, with strategic executions of digital asset solutions for institutional and HNWI clients.'
        },
        {
          name:'Jonathan Yu',
          role: 'Senior Relationship Manager (Institutional Sales)',
          photo:require('@/assets/images/team/Jonathan Yu.png'),
          content: 'With over 10 years of experience in the Financial Services Industry, Jonathan is an experienced account manager, with extensive knowledge in trading and investing. His experience ranges from global brokering houses, venture capital, and in selling mission critical financial technology. Jonathan is dedicated to delivering trusted and reliable services to all his clients.'
        },
        {
          name:'Carina Lee',
          role: 'Relationship Manager',
          photo:require('@/assets/images/team/Carina Lee.png'),
          content: 'Carina has over 9 years of professional experience managing high net worth clients’ portfolios as a Relationship Manager in major banks including Standard Chartered Bank, OCBC, and United Overseas Bank. Awarded the Capital Markets & Financial Advisory Service certification, Carina has international banking exposures covering Malaysia and Brunei. Carina believes in maintaining high service standards and driving business growth through a dedicated and long-term fruitful relationship with business partners and clients. '
        },
        {
          name:'Eve Lim',
          role: 'Relationship Manager',
          photo:require('@/assets/images/team/Eve Lim-2.png'),
          content: 'With over 9 years of experience within the banking and finance industry, Eve is committed to managing various profiles of clients including High Net Worths at leading banks such as United Overseas Bank, Standard Chartered Bank, and BNP Paribas. With vast knowledge and professional experience in a full suite of financial products, including insurance, bonds, unit trusts, equity-linked notes, structured products, leverage, and loan switches and as well as premium currency investments, Eve believes in ensuring high compliance towards onboarding in areas of KYC, AML, and CFT. '
        },
        {
          name:'Thaddeus Ong',
          role: 'Senior Trader',
          photo:require('@/assets/images/team/Thaddeus Ong.png'),
          content: 'Thaddeus is a Senior Trader with Sparrow with over 5 years of trading experiences in cryptocurrencies, equities spot and derivatives. In addition, Thaddeus had accumulated strong knowledge of Cryptocurrency market cycles, Altcoin Fundamentals and DeFi Ecosystem. As the founder of the Everything About Bitcoin, Thaddeus is committed to delivering educational insights and tailored FAQ on subjects relating to Bitcoin in the Singapore market. As a specialized trader at Sparrow, Thaddeus monitors market movements, analyzes performances and partners with the digital asset solutions team to offer sound and tailored solutions for financial institutions and wealth managers.'
        },
      ],
      isModalVisible: false,
      userSelected: null,
    }
  },
  mounted() {
    this.initArrows()
  },
  methods: {
    initArrows:function(){
      $(document).ready(function(){
        $('.c-team__name').each(function(){
          let fullWidth = $(this).width();
          let nameWidth = $(this).find('.c-team__name-text').width();
          let arrowWidth = fullWidth - nameWidth - 5;
          $(this).find('.c-team__arrow-container').width(arrowWidth+'px')
        })
      })
    },
    showModal(dt) {
      this.userSelected = dt
      this.isModalVisible = true;
      $("body").css("overflow-y", "hidden");
    },
    closeModal() {
      this.isModalVisible = false;
      $("body").css("overflow-y", "auto");
    },
    toggleHead() {
      let _this = this;
      this.headClicked = !this.headClicked
      this.$nextTick(()=>{
        _this.adjustHeight(5, -1, -1);
      })
    },
    toggleBody(index) {
      let _this = this;
      this.statusBody[index].clicked = !this.statusBody[index].clicked
      this.$nextTick(()=>{
        _this.adjustHeight(5, index, -1);
      })
    },
    toggleSubBody(idxBody, idxSub) {
      let _this = this;
      this.statusBody[idxBody].statusSubBody[idxSub].clicked = !this.statusBody[idxBody].statusSubBody[idxSub].clicked
      this.$nextTick(()=>{
        _this.adjustHeight(5, idxBody, idxSub);
      })
    },
    adjustHeight:function(idx, idxChild, idxGrandChild){
      let grandChildElements, childElements, bodyElements
      bodyElements = document.getElementsByClassName('c-faq-reorganize__list-body')
      if(idxGrandChild>=0){
        childElements = bodyElements[idx].getElementsByClassName('c-faq-reorganize__list-subbody')
        grandChildElements = childElements[idxChild].getElementsByClassName('c-faq-reorganize__list-subbody-content-child-list')
        if(grandChildElements[idxGrandChild].classList.contains('c-faq-reorganize__open-grandchild')){
          let grandChildBodyHeight = grandChildElements[idxGrandChild].scrollHeight + 90
          grandChildElements[idxGrandChild].style.maxHeight = grandChildBodyHeight+'px'
          this.scrollToFAQ(grandChildElements[idxGrandChild].offsetTop);
        }
        else{
          grandChildElements[idxGrandChild].style.maxHeight ='0px'
        }
        childElements[idxChild].style.maxHeight = '1500px'
        bodyElements[idx].style.maxHeight = '9999px'
      }
      else{
        if(idxChild>=0){
          childElements = bodyElements[idx].getElementsByClassName('c-faq-reorganize__list-subbody')
          if(childElements[idxChild].classList.contains('c-faq-reorganize__open-child')){
            let childBodyHeight = childElements[idxChild].scrollHeight + 90
            childElements[idxChild].style.maxHeight = childBodyHeight+'px'
            this.scrollToFAQ(childElements[idxChild].offsetTop);
          }
          else{
            childElements[idxChild].style.maxHeight = '0px'
          }
          bodyElements[idx].style.maxHeight = '9999px'
        }
        else{
          if(bodyElements[idx].classList.contains('c-faq-reorganize__list-body_active')){
            let bodyHeight = bodyElements[idx].scrollHeight + 90
            bodyElements[idx].style.maxHeight = bodyHeight+'px'
            this.scrollToFAQ(bodyElements[idx].offsetTop);
          }
          else{
            bodyElements[idx].style.maxHeight = '0px'
          }
        }
      }
    },
    scrollToFAQ(position) {
      $('html,body').animate({scrollTop: position - 170},'slow');
    }
  }
}
</script>

<template>
  <div class="page">
    <div class="c-page o-page-wrapper c-page_partner">
      <Sidebar :status="'support'" :elementClass="'false'"></Sidebar>
      <div class="o-sidebar-content">
        <PageBanner :bannerData = "bannerData" :theme="theme"></PageBanner>
        <div class="c-page__content">
          <FaqContent></FaqContent>
          <Faq></Faq>
          <FaqContentEthereumMerge></FaqContentEthereumMerge>
          <FaqEthereumMerge></FaqEthereumMerge>
          <FaqContentReorganize></FaqContentReorganize>
          <FaqReorganize></FaqReorganize>
          <FaqContentIPA></FaqContentIPA>
          <FaqIpa></FaqIpa>
          <div class = "c-faq__tradenow">
            <TradeNow v-if="!token"></TradeNow>
          </div>
          <LikeSuggestion></LikeSuggestion>
          <GetStartedContent></GetStartedContent>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'
import PageBanner from '@/components/PageBanner.vue'
import FaqContent from '@/components/support/FaqContent.vue'
import Faq from '@/components/support/FAQ.vue'
import FaqContentReorganize from '@/components/support/FaqContentReorganize.vue'
import FaqContentEthereumMerge from '@/components/support/FaqContentEthereumMerge.vue'
import FaqReorganize from '@/components/support/FAQReorganize.vue'
import TradeNow from '@/components/TradeNow.vue'
import GetStartedContent from '@/components/GetStartedContent.vue'
import LikeSuggestion from '@/components/LikeSuggestion.vue'
import FaqContentIPA from '@/components/support/FaqContentIPA.vue'
import FaqIpa from '@/components/support/FAQIPA.vue'
import FaqEthereumMerge from '@/components/support/FAQEthereumMerge.vue'
export default {
  name: 'TroubleshootCenter',
  components: {
    Sidebar,
    Footer,
    PageBanner,
    FaqContent,
    Faq,
    TradeNow,
    GetStartedContent,
    LikeSuggestion,
    FaqReorganize,
    FaqContentReorganize,
    FaqContentEthereumMerge,
    FaqContentIPA,
    FaqIpa,
    FaqEthereumMerge
  },
  data() {
    return {
      bannerData: [
        {
          img : require('@/assets/images/banner/Diverse range of innovative products.jpg'),
          title:"Diverse range of innovative <br />products <span class='c-page-banner__regular'>for institutions and </span>",
          subtitle:"accredited clients",
          buttonText:"Find out more >",
          buttonPage:"/institutional/wealth"
        },
      ],
      theme: 'two',
      hideIPA: false,
    }
  },
  mounted:function(){
    this.updateTitleMeta('Sparrow | Frequently Asked Questions on Getting Started With Sparrow')
    this.updateDescriptionMeta("Sparrow answers technical troubleshoots relating to registration, security, verification, regulatory compliance, trading activities, and option contract specifications.")
    this.updateKeywordMeta('Sparrow, option tradings')
  },
  computed: {
    token:function(){
      return this.$store.getters.token;
    },
  }
}
</script>
